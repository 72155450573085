import * as React from 'react';
import { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Icons from '../assets/icons/icons';
import Loader from './layout/Loader';
import ScrollToTop from './layout/ScrollToTop';
import routesOnePage from '../route';
import routes from '../routes';
import { Redirect } from 'react-router-dom';
import Config from '../config';

import IdleTimer from "../store/IdleTimer";
import _ from 'lodash';

import DiscourseStart from '../Pages/DiscoursePage/DiscourseStart';

const NotFoundPage = React.lazy(() => import('../Pages/NotFound/NotFoundPage'));
// const DiscourseStart = React.lazy(() => import('../Pages/DiscoursePage/DiscourseStart'));



const AdminLayout = lazy(() => import('./layout/AdminLayout'));
const App = () => {
    const location = useLocation();
    const history = useHistory();
    const ipparams = window.localStorage.getItem('ipparams') ?? 'sg';
    const [redirected, setRedirected] = React.useState(false);
    const url = new URL(window.location.href);
    const splURL = _.split(_.trim(url.pathname, "/") , "/");


    React.useEffect(() => {
        // Swal.close();

        let activepopup = Swal.getPopup();

        if(!_.isNull(activepopup)) {
            Swal.close();
        } //Fix on closing active popup when navigating to other page 
    }, [location]);


    React.useEffect(() => {
        if(splURL.includes('sg') || splURL.includes('my') || splURL.includes('discourse-start')) {
            setRedirected(true)
        }
    }, [])


    useEffect(() => {
        if(!_.isNull(localStorage.getItem('jwt')) 
        || splURL.includes('login') 
        || splURL.includes('logout')
        || splURL.includes('speedupgrade')) {
            // console.log('not_idle');
            if(!_.isNull(localStorage.getItem("_expiredTime"))) {
                localStorage.removeItem("_expiredTime")
            }
            return;
        }
          
        const timer = new IdleTimer({
          timeout: 300, //expire after 5mins = 300s
          onTimeout: () => {
            const MySwal = withReactContent(Swal);
            MySwal.fire({
                // iconHtml: Icons.swal.question,
                iconHtml: <lord-icon src="https://cdn.lordicon.com/enzmygww.json" trigger="hover" colors="primary:#d4574d" />,
                html: '<b>You have been logged out due to inactivity. Please log in again to continue.</b>',
                confirmButtonText: 'Back to Log in Page',
                confirmButtonColor: '#d4574d',
                allowOutsideClick: false,
                customClass: {
                    popup: 'error-cancel swal-manage-subscription-page'
                }
            }).then((res3) => {
                if(res3.isConfirmed){
                    localStorage.clear();
                    // history.replace(`/${ipparams}/login`)
                    history.push('/')
                }
            });  
          },
          onExpired: () => {
            // do something if expired on load
            // alert('Expired')
          },
        });

        return () => {
          timer.cleanUp();
        };
    }, [history, ipparams]);

    // if(checkdiscoursepage) {

    //     return (<>

    //         {/* <Redirect to={'/discourse-start'} /> */}
    //         <Route path={'/discourse-start'} component={DiscourseStart} />
    //     </>)
    // } else {

    return (<>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Route path={routesOnePage.map((x) => x.path)}>
                        <Switch location={location} key={location.pathname}>
                            {routesOnePage.map((route, index) => {
            return route.component ? (<Route key={index} path={route.path} exact={route.exact} render={(props) => <route.component {...props}/>}/>) : null;
        })}
                        <Redirect from='*' to={Config.defaultPath} />
                        </Switch>
                    </Route>
                    <Route path={routes.map((x) => x.path)}>
                        <AdminLayout />
                    </Route>
                    <Route path={'/'} exact>
                        <Redirect to={Config.defaultPath}/>
                    </Route>
                    {!redirected && <Route path="*" component={NotFoundPage} />}
                    
                </Suspense>
            </ScrollToTop>
            <div className="backdrop"/>
        </>);
    
    // }

    
    
};
export default App;
